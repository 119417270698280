@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Caveat:wght@400;500;600&family=Comic+Neue:ital,wght@0,700;1,700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main{
  padding-top: 4em;
}
.body{
  padding:0.6em;
  padding-bottom: 6em;
}
.center{
  align-items: center;
  text-align: center;
}

h3{
  font-size: 1.8em;
  text-align: center;
}

.h6{
  color:#753AD5;
  font-weight: 600;
  text-align: center;
  font-size: 1.1em;
  padding:0.7em;
}

.leaderboard-holder{

}

.para{
  text-align: center;
}
.score{
  font-weight: 600;
  margin-top:-15px;
  font-size: 2.1em;
  position: absolute;
  z-index: 3;
  color:white;
  /* border:2px solid #960474; */
  -webkit-text-stroke: 1px #ee7f24;
    -webkit-text-fill-color: #e9c835;
  /* font-family:  'Caveat', cursive; */
  font-family: 'Comic Neue', cursive;
}

.coin{
  font-size: 1em;
  font-weight: bolder;
  color:rgb(247, 183, 9);
}

sup{
  font-size: medium;
}

.card{
  margin:15px 5px;
  padding:10px;
  color:red;
}

.logo{
  height:50px
}

.mainColor{
  color:'#753AD5';
}

form{
  width: 100%;
  text-align: center;
}

.topLeftTringle{
  width: 0;
  height: 0;
  border-top: 165px solid #743ad5;
  border-right: 165px solid transparent;
  opacity:0.85;
  color:#fff;
  /* background: linear-gradient(to left, #743ad5, #d53a9d); */
}

.gameLabel{
  width: 100%;
  height: auto;
  padding:5;
  font-size:1.4em;
  opacity:0.9;
  color:#fff;
  font-weight: 500;
  background: linear-gradient(to left, #743ad5, #d53a9d);
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}